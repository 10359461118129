import React, { useEffect } from 'react';
import Hero from '../Hero';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import PortfolioSlider from '../Slider/PortfolioSlider';
import TestimonialSlider from '../Slider/TestimonialSlider';
import VideoModal from '../VideoModal';
import { pageTitle } from '../../helper';
import FunFact2 from '../FunFact/FunFact2';
import ServiceList from '../ServiceList';
import Card from '../Card';
import Portfolio2 from '../Portfolio/Portfolio2';
import LogoList from '../LogoList';
import PostSlider from '../Slider/PostSlider';

export default function Home() {
  pageTitle('Ana Sayfa');

  // Hero Social Links
  const heroSocialLinks = [
    {
      name: 'İnstagram',
      links: '/',
    },
    {
      name: 'Twitter',
      links: '/',
    },
  ];

  // FunFact Data
  const funfaceData = [
    {
      title: 'Senelik Tecrübe',
      factNumber: '50',
    },
    {
      title: 'Verilen Hizmet',
      factNumber: '100',
    },
    {
      title: 'Ekip Üyesi',
      factNumber: '30',
    },
    {
      title: 'Olumlu Yorum',
      factNumber: '100',
    },
  ];

  const portfolioData = [
    {
      title: 'Donanımlı ve tecrübeli teknik kadrosu ile her geçen gün genişleyen ve sektörünün en iyisi olma yolunda emin adımlarla ilerlemekteyiz.',
      subtitle: 'Project 01',
      btnText: 'See Details',
      btnLink: '/',
      imageUrl: '/images/wallpaperbetter (2).jpg',
      category: 'Karademir Group',
    }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Start Hero Section */}
      <Hero
        title="Değerlerimizle İnşa Ediyoruz, <br/> Geleceği Şekillendiriyoruz..."
        subtitle=""
        btnText="Hakkımızda"
        btnLink="/Hakkimizda"
        scrollDownId="#service"
        socialLinksHeading="Takip Et"
        heroSocialLinks={heroSocialLinks}
        bgImageUrl="./images/wallpaperbetter.jpg"
      />
      {/* End Hero Section */}

      {/* Start Funfact Section */}
        <Div className="container">
          <FunFact2
            data={funfaceData}
            variant="cs-type1"
            bgUrl="/images/funfact_shape_bg.svg"
          />
      </Div>
      {/* End Funfact Section */}

      {/* Start Services Section */}
      <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Karademir Group Olarak Sunduğumuz Hizmetler"
          subtitle="Hizmetlerimiz"
          variant="cs-style1 text-center"
        />
        <Spacing lg="70" md="45" />
        <ServiceList />
      </Div>
      {/* End Services Section */}

       {/* Start Portfolio Section */}
       <Spacing lg="150" md="80" />
      {portfolioData.map((item, index) =>
          <Div key={index}>
            <Portfolio2
              title={item.title}
              subtitle={item.subtitle}
              btnText={item.btnText}
              btnLink={item.btnLink}
              imageUrl={item.imageUrl}
              category={item.category}
            />
            <Spacing lg="100" md="70" />
          </Div>
      )}
      {/* End Portfolio Section */}

       {/* Start Why Choose Section */}
       <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
        <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Ne İçin Çabalıyoruz?"
              subtitle="KARADEMİR GROUP"
            >
               <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Şirketimizin geleceği ve değerli müşterilerimizin talepleri doğrultusunda elimizden geleni yapıyoruz.</p>
              <Spacing lg="30" md="20" />
              <h5>Vizyonumuz</h5>
              <p className="cs-m0">
              Vizyonumuz, şirketimizin geleceği yönelik yatırımları ile kömür, inşaat ve uzun dönem araç kiralama sektöründe yapmış ve yapacak olduğu iyileştirmelerle sürdürmüş olduğu varlığın daha üst seviyeye taşımasıdır.
              </p>
              <Spacing lg="15" md="15" />
              <h5>Misyonumuz</h5>
              <p className="cs-m0">
              Misyonumuz ise yüksek kalitede ve düşük maliyetle müşterilerimizin taleplerine cevap verebilmek ve memnuniyetlerini sağlamaktır.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/6.jpg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Why Choose Section */}

      {/* Start Portfolio Section */}
      {/* <Spacing lg="150" md="50" />
      <Div>
        <Div className="container">
          <SectionHeading
            title="Projelerimiz"
            subtitle=""
            variant="cs-style1 text-center"
          />
          <Spacing lg="90" md="45" />
        </Div>
        <PortfolioSlider />
      </Div> */}
      {/* End Portfolio Section */}

       {/* Start Service Section */}
       <Spacing lg="130" md="70" />
       <Div className='cs-shape_wrap_4'>
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title='Sahip Olduğumuz Değerler' 
                subtitle='DEĞERLERİMİZ'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className='col-xl-8'>
              <Div className='row'>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Adalet'
                    link='/'
                    src='/images/adalet.jpg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='İnsan Odaklılık'
                    link='/'
                    src='/images/insanodakli.jpg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='İtibar'
                    link='/'
                    src='/images/itibar.jpg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Gönülden Çalışmak'
                    link='/'
                    src='/images/calismak.jpg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Sürdürebilirlik'
                    link='/'
                    src='/images/surdurebilirlik.jpg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='Çeviklik'
                    link='/'
                    src='/images/ceviklik.jpg'
                    alt='Service'
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Service Section */}

      {/* Start Video Block Section */}
      <Div className="container">
        {/* <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4">
        Devler gibi eserler bırakmak için, karıncalar gibi çalışmak gerekir.
        </h2> */}
        {/* <Spacing lg="70" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/watch?v=VcaAVWtP48A"
          bgUrl="/images/video_bg.jpeg"
        /> */}
      </Div>
      {/* End Video Block Section */}

       {/* Start Blog Section */}
       {/* <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Spacing lg="145" md="80" />
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-5 col-xl-4">
              <SectionHeading
                title="Gündem"
                subtitle="Son Gelişmeler"
                btnText="Daha Fazlası"
                btnLink="/blog"
              />
              <Spacing lg="45" md="45" />
            </Div>
            <Div className="col-lg-7 offset-xl-1">
              <PostList />
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg="45" md="80" /> */}
      {/* End Blog Section */}
      {/* <Spacing lg="100" md="45" /> */}
      {/* Start LogoList Section */}
        <Div className="container">
        {/* <SectionHeading
          title="Grup Şirketlerimiz"
          subtitle=""
          variant="cs-style1 text-center"
        /> */}
          {/* <Spacing lg="75" md="80" /> */}
          {/* <LogoList /> */}
      </Div>
      {/* End LogoList Section */}

    {/* Start Blog Section */}
    {/* <Spacing lg="150" md="80" />
      <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Blog sayfamızda yer alan son paylaşımlar"
                subtitle="Blog"
                btnText="Daha Fazlası"
                btnLink="/blog"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <Div className="cs-half_of_full_width">
                <PostSlider />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div> */}
      {/* End Blog Section */}
      <Spacing lg="100" md="45" />
      <hr/>
      {/* Start Testimonial Section */}
      {/* <TestimonialSlider /> */}
      {/* End Testimonial Section */}
    </>
  );
}
