import React from 'react'
import Div from '../Div'
import ContactInfoWidget from '../Widget/ContactInfoWidget'
import MenuWidget from '../Widget/MenuWidget'
import Newsletter from '../Widget/Newsletter'
import SocialWidget from '../Widget/SocialWidget'
import TextWidget from '../Widget/TextWidget'
import './footer.scss'

export default function Footer({copyrightText, logoSrc, logoAlt, text}) {
  const copyrightLinks = [
    {
      title: 'Terms of Use',
      href: '/'
    },
    {
      title: 'Privacy Policy',
      href: '/'
    }
  ]
  
  const serviceMenu = [
    {
      title: 'Ana Sayfa',
      href: '/'
    },
    {
      title: 'Faaliyet Alanları',
      href: '/service/service-details'
    },
    {
      title: 'Hakkımızda',
      href: '/Hakkimizda'
    },
    {
      title: 'İletişim',
      href: '/Iletisim'
    },
    // {
    //   title: 'Blog',
    //   href: '/Blog'
    // }
  ]

  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            {/* <Div className="col-lg-4 col-sm-12">
              <Div className="cs-footer_item">
                <TextWidget 
                  logoSrc='/images/karademirlogo.png' 
                  logoAlt='Logo'
                  text ='Sosyal kanallarımıza abone ol, gelişmelerden haberdar ol.'
                />
                <SocialWidget/>
              </Div>
            </Div> */}
            <Div className="col-lg-6 col-sm-12">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu} menuHeading='Bağlantılar'/>
              </Div>
            </Div>
            <Div className="col-lg-6 col-sm-12">
              <Div className="cs-footer_item">
                <ContactInfoWidget title='Şirket Bilgileri' withIcon/>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">Tüm Hakları Saklıdır @ KarademirGroup 2024 - Designed By. <a target="blank" href="https://on1medya.com/">ON1 MEDYA</a></Div>
          </Div>
          {/* <Div className="cs-bottom_footer_right">
            <MenuWidget menuItems={copyrightLinks} variant=' cs-style2'/>
          </Div> */}
        </Div>
      </Div>
    </footer>
  )
}
