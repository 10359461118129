import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Button from '../Button'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import Spacing from '../Spacing'

export default function PortfolioDetailsPage() {
  const portfolioData = [
    {
      title: 'KARADEMİR GROUP PETROL MADEN ANONİM ŞİRKETİ',
      subtitle: 'Detayı',
      href: '/portfolio/1',
      src: '/images/komurdetay.png',
      category: 'komur',
      email: 'info@karademirgroup.com',
      id: 1,
      location: 'Hilal mahallesi 674.sokak No:4/5 Çankaya/Ankara',
      date: '14.07.2023',
      text: ['Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium voltire doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.',
       'Ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit.', ]
    },
    {
      title: 'EKR İNŞAAT İÇ VE DIŞ TİCARET LTD ŞTİ',
      subtitle: 'Detayı',
      href: '/portfolio/2',
      src: '/images/insaatdetay.png',
      category: 'insaat_arac_kiralama',
      id: 2,
      location: 'Adalet Mah.Manas Bulvarı No:47/B Folkart Towers A Kule K:26 D:2601 Bayraklı/İzmir/Türkiye',
      sube: 'Akköprü Mah.Özalp yolu caddesi Kömürcüler Sitesi No:100/42 Tuşba/Van/Türkiye',
      date: '14.07.2023',
      email: '',
      text: ['Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium voltire doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.',
      'Ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit.', ]
    },
    {
      title: 'YENİ GLC GIDA HAYVANCILIK TURZ İNŞ SAN VE TİC LTD ŞTİ',
      subtitle: 'Detayı',
      href: '/portfolio/3',
      src: '/images/portfolio_7.jpeg',
      category: 'yeme_icme',
      id: 3,
      location: 'Ümit mah.2479 Cad No:2/30 Çankaya/Ankara',
      email: 'info@kapikasapsteak.com ',
      date: '14.07.2023',
      text: ['Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium voltire doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.',
      'Ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit.', ]
    },
    {
      title: 'HAZAR MADENCİLİK VE NAKLİYAT LİMİTED ŞİRKETİ',
      subtitle: 'Detayı',
      href: '/portfolio/4',
      src: '/images/komurdetay.png',
      category: 'maden',
      id: 4,
      location: 'Akköprü Mah. Kömürcüler Sk. No:34 Tuşba/Van',
      email: ' ',
      date: '14.07.2023',
      text: ['Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium voltire doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.',
      'Ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit.', ]
    }
  ];

  const params = useParams()
  pageTitle('Faaliyet Alanı Detayı');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title={portfolioData[params.portfolioDetailsId - 1].title}
        bgSrc={portfolioData[params.portfolioDetailsId - 1].src}
        pageLinkText={portfolioData[params.portfolioDetailsId - 1].title}
      />
      <Spacing lg='150' md='80'/>
      <Div className="container">
        {/* <img src={portfolioData[params.portfolioDetailsId - 1].src} alt="Details" className="cs-radius_15 w-100" /> */}
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading 
              title={portfolioData[params.portfolioDetailsId - 1].title} 
              subtitle='' 
            >
              <Spacing lg='40' md='20'/>
              {/* {portfolioData[params.portfolioDetailsId - 1].text.map((item, index) => (
                <div>
                  <Spacing lg='10' md='10'/>
                  <p>{item}</p>
                </div>
              ))} */}
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-lg-1">
            <Spacing lg='60' md='40'/>
            <h2 className='cs-font_30 cs-font_26_sm cs-m0'>Detaylar</h2>
            <Spacing lg='50' md='30'/>
            <Div className="row">
              {/* <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Category:</h3>
                <p className='cs-m0'>Artwork</p>
                <Spacing lg='30' md='30'/>
              </Div> */}
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Konum:</h3>
                <p className='cs-m0'>{portfolioData[params.portfolioDetailsId - 1].location} </p>
                <Spacing lg='30' md='30'/>
              </Div>
              {/* <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Software:</h3>
                <p className='cs-m0'>Adobe Illustrator</p>
                <Spacing lg='30' md='30'/>
              </Div> */}
              <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Email:</h3>
                <p className='cs-m0'>{portfolioData[params.portfolioDetailsId - 1].email} </p>
                <Spacing lg='30' md='30'/>
              </Div>
              <Div className="col-6">
                  <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Şube:</h3>
                  <p className='cs-m0'>{portfolioData[params.portfolioDetailsId - 1].sube} </p>
                  <Spacing lg='30' md='30'/>
                </Div>
              {/* <Div className="col-6">
                <h3 className='cs-accent_color cs-font_22 cs-font_18_sm cs-m0'>Client:</h3>
                <p className='cs-m0'>Andreo Bowla</p>
                <Spacing lg='30' md='30'/>
              </Div> */}
            </Div>
          </Div>
        </Div>
        <Spacing lg='65' md='10'/>
          {/* <Div className="cs-page_navigation cs-center">
            <Div>
              <Button btnLink='/portfolio/portfolio-details' btnText='Prev Project' variant='cs-type1'/>
            </Div>
            <Div>
              <Button btnLink='/portfolio/portfolio-details' btnText='Next Project'/>
            </Div>
          </Div> */}
      </Div>
      <hr/>
      {/* <Cta 
        title='agency@arino.com' 
        bgSrc='/images/cta_bg_2.jpeg'
        variant='rounded-0'
      /> */}
    </>
  )
}
