import { Icon } from '@iconify/react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Portfolio from '../Portfolio';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';

export default function PortfolioPage() {
  pageTitle('Faaliyet Alanları');
  const [active, setActive] = useState('all');
  const [itemShow, setItemShow] = useState(10);
  const portfolioData = [
    {
      title: 'Kömür',
      subtitle: 'Detayı',
      href: '/portfolio/1',
      src: '/images/maden.png',
      category: 'komur',
      id: 1
    },
    {
      title: 'İnşaat ve Araç Kiralama',
      subtitle: 'Detayı',
      href: '/portfolio/2',
      src: '/images/insaat.png',
      category: 'insaat_arac_kiralama',
      id: 2
    },
    {
      title: 'Yeme-İçme',
      subtitle: 'Detayı',
      href: '/portfolio/3',
      src: '/images/portfolio_3.jpeg',
      category: 'yeme_icme',
      id: 3
    },
    {
      title: 'Maden-Akaryakıt',
      subtitle: 'Detayı',
      href: '/portfolio/4',
      src: '/images/portfolio_1.jpeg',
      category: 'maden',
      id: 4
    }
  ];
  const categoryMenu = [
    {
      title: 'Kömür',
      category: 'komur',
    },
    {
      title: 'İnşaat ve Araç Kiralama',
      category: 'insaat_arac_kiralama',
    },
    {
      title: 'Yeme-İçme',
      category: 'yeme_icme',
    },
    {
      title: 'Maden-Akaryakıt',
      category: 'maden',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeading
        title="Faaliyet Alanları"
        bgSrc="images/portfolio_hero_bg.jpeg"
        pageLinkText="Faaliyet Alanları"
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="Hizmet Verdiğimiz Alanlar" subtitle="" />
          <Div className="cs-filter_menu cs-style1">
            <ul className="cs-mp0 cs-center">
              <li className={active === 'all' ? 'active' : ''}>
                <span onClick={() => setActive('all')}>Hepsi</span>
              </li>
              {categoryMenu.map((item, index) => (
                <li
                  className={active === item.category ? 'active' : ''}
                  key={index}
                >
                  <span onClick={() => setActive(item.category)}>
                    {item.title}
                  </span>
                </li>
              ))}
            </ul>
          </Div>
        </Div>
        <Spacing lg="90" md="45" />
        <Div className="row">
          {portfolioData.slice(0, itemShow).map((item, index) => (
            <Div
              className={`${
                index === 3 || index === 6 ? 'col-lg-8' : 'col-lg-4'
              } ${
                active === 'all'
                  ? ''
                  : !(active === item.category)
                  ? 'd-none'
                  : ''
              }`}
              key={index}
            >
              <Portfolio
                title={item.title}
                subtitle={item.subtitle}
                href={item.href}
                src={item.src}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>
      </Div>
    </>
  );
}
