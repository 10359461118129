import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import FunFact from '../FunFact';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import TeamSlider from '../Slider/TeamSlider';
import Spacing from '../Spacing';

export default function AboutPage() {
  pageTitle('Hakkımızda');

  const funfaceData = [
    {
      title: 'Senelik Tecrübe',
      factNumber: '50',
    },
    {
      title: 'Verilen Hizmet',
      factNumber: '100',
    },
    {
      title: 'Ekip Üyesi',
      factNumber: '30',
    },
    {
      title: 'Olumlu Yorum',
      factNumber: '100',
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Start Page Heading Section */}
      <PageHeading
        title="Hakkımızda"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="Hakkımızda"
      />
      {/* End Page Heading Section */}

      {/* Start About Section */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-8 col-lg-8">
              <p className="cs-m0">
              Sürekli kendini geliştirerek hayalleri gerçeğe dönüştüren Karademir Group çeyrek asırdır devam eden kömür, inşaat, uzun dönem araç kiralama faaliyetlerinin yanı sıra yeme içme sektöründe de çalışmalarını başarıyla sürdürmektedir. Çeyrek asrı geride bıraktığımız sektör deneyimimizde sayısız işbirliği sağlayarak ülkemizin zengin maden yataklarından alın teri ve emekle çıkarılıp işlenen kömürleri başarılı bir şekilde iş ortaklarımıza ulaştırdık.
              </p>
              <Spacing lg="25" md="25" />
              <p className='cs-m0'>
              Yerli ve ithal kömür satışı yapan firmamız her zaman büyümeyi hedef bilmiş bu sektörde Türkiye’nin her bölgesinde yerli ve ithal kömür satışı ağını genişletmiştir.
              </p>
              <Spacing lg="25" md="25" />
              <p className='cs-m0'>
              Karademir Group Kömür, inşaat, uzun dönem araç kiralama ve yeme içme sektöründe de paydaş odaklı yaklaşımı uygulamaları daima ön planda tutmuş ve kaliteli kesintisiz hizmeti işbirlikçilerine nitelikli olarak vermeye devam etmektedir. Daima yenilenen teknolojiyi benimseyen ve dinamik yapımızla yıllardır hizmet vermeyi sürdürdük. Kurulduğumuz ilk günden beri doğal madeni kaynaklarımızın özenle işlenerek satışa sunulduğu sektörümüzde müşteri memnuniyetine ön planda tutarak önemli işbirliklerine imza attık. Alanında uzman kadromuzun destekleriyle her yıl değişimlerine daha ileri taşımak isteyen firmamızla birlikte benimsediğimiz hedeflerimize odaklandık.
              </p>
              <Spacing lg="25" md="25" />
              <p className='cs-m0'>
              Türkiye’nin gelişmekte olan ekonomi ve sanayisine katkıda bulunarak katma değerini artırtmaktadır. Tüm bunlara ek olarak Karademir Group sadece ticari alanda değil; toplumsal katkılarıyla da önemli bir kurum haline gelmiştir.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
          </Div>
          {<Div className="col-lg-4">
            <img style={{marginTop: '90px'}}
              src="/images/wallpaperbetter (1).jpg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>}
        </Div>
      </Div>
      <Spacing lg="75" md="55" />
      {/* End About Section */}

      {/* Start Fun Fact Section */}
      <Div className="container">
        <FunFact
          title=""
          subtitle="Yeteneklerimiz ve enerjimizle faaliyette bulunduğumuz tüm alanlarda insan hayatına değer katacak çözümler üretiyoruz."
          data={funfaceData}
        />
      </Div>
      {/* End Fun Fact Section */}

      {/* Start Why Choose Section */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/about_img_4.jpeg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Vizyon & Misyon"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <h5>Vizyonumuz</h5>
              <p className="cs-m0">
              Vizyonumuz, şirketimizin geleceği yönelik yatırımları ile kömür, inşaat ve uzun dönem araç kiralama sektöründe yapmış ve yapacak olduğu iyileştirmelerle sürdürmüş olduğu varlığın daha üst seviyeye taşımasıdır.
              </p>
              <Spacing lg="15" md="15" />
              <h5>Misyonumuz</h5>
              <p className="cs-m0">
              Misyonumuz ise yüksek kalitede ve düşük maliyetle müşterilerimizin taleplerine cevap verebilmek ve memnuniyetlerini sağlamaktır.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Why Choose Section */}

      {/* Start Team Section */}
      {/* <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Awesome team <br/>members"
          subtitle="Our Team"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        <TeamSlider />
      </Div> */}
      {/* End Team Section */}

      {/* Start CTA Section */}
      {/* <Spacing lg="150" md="80" />
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div> */}
      {/* End CTA Section */}
    </>
  );
}
